<template>
  <!-- 分配美容师 -->
  <div class="beaBox">
    <div class="beaList">
      <div class="beacont" v-for="(item, index) in beautList" :key="index" @click="handCheck(item.checked, index)">
        <img class="beaLogo" v-if="item.furl !== null" :src="item.furl" alt="" >
        <img class="beaLogo"  v-if="item.furl === null" src="../../assets/img/users.png" alt="" >
        <p style="font-weight: 600;">{{ item.sbecode }}</p>
        <p style="margin: 4px 0px;">{{ item.sysvalue }}</p>
        <el-checkbox v-model="item.checked" @change="handCheck(item.checked, index)"></el-checkbox>
      </div>
    </div>

    <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
      <div class="settingCancel" @click="handCancel">取消</div>
      <div class="settingConfirm" @click="handSubmit">确定</div>
    </div>
  </div>
</template>

<script>
import { allocationQuery } from '@/api/store'
export default {
  data () {
    return {
      beautList: [],
      selectId: ''
    }
  },
  created () {
    this.getBeaut()
  },
  methods: {
    getBeaut () {
      allocationQuery().then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            res.data.data.forEach((item) => {
              item.checked = false
            })
            this.beautList = res.data.data
          }
        }
      })
    },
    handCheck (val, eq) {
      for (let i = 0; i < this.beautList.length; i++) {
        this.beautList[i].checked = false
      }
      this.beautList[eq].checked = true
      this.selectId = this.beautList[eq]
    },
    handCancel () {
      this.$emit('closeCancel')
    },
    handSubmit () {
      if (this.selectId === '') {
        this.$message.warning('请选择美容师')
      } else {
        this.$emit('selectConfirm', this.selectId)
      }
    }
  }
}
</script>

<style scoped>
.beaBox {
  width: 100%;
  padding: 20px;
  min-height: 350px;
  box-sizing: border-box;
}
.beaList{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.beacont {
  margin-right: 30px;
  margin-bottom: 30px;
  width: 120px;
  height: 200px;
  border-radius: 8px;
  border: 1px solid #DDDDDD;
  text-align: center;
  cursor: pointer;
}
.beaLogo{
  width: 120px;
  height: 120px;
  margin-bottom: 8px;
}
</style>

import { post } from '@/http/http'

// 预约列表查询
export const indentGet = p => post('api/v1.0/indentinfo/indent_reserve_get', p)

// 预约添加
export const indentAdd = p => post('api/v1.0/indentinfo/indent_reserve_add', p)

// 预约列表统计
export const indentNum = p => post('api/v1.0/indentinfo/indent_reserve_num', p)

// 预约(确认/分配/取消)
export const indentOper = p => post('api/v1.0/indentinfo/indent_reserve_oper', p)

// 预约(流程)
export const indentSpeed = p => post('api/v1.0/indentinfo/indent_reserve_process', p)

// 预约(详情)
export const indentDetail = p => post('api/v1.0/indentinfo/indent_reserve_get_id', p)

// 充值-查询
export const rechargeQuery = p => post('api/v1.0/indentinfo/indent_topup_get', p)

// 充值-关联抵扣卡 - 查询
export const rechargeBondQuery = p => post('api/v1.0/indentinfo/indent_topup_as_ded_get', p)

// 充值-添加
export const rechargeAdd = p => post('api/v1.0/indentinfo/indent_topup_add', p)

// 充值-分配美容师
export const rechargeCosmet = p => post('api/v1.0/indentinfo/indent_topup_sbe_update', p)


// 套餐卡列表查询
export const incardGet = p => post('api/v1.0/indentinfo/indent_card_get', p)

// 套餐卡添加
export const incardAdd = p => post('api/v1.0/indentinfo/indent_card_add', p)

// 套餐卡详情查询
export const markDetail = p => post('api/v1.0/indentinfo/markete_card_details', p)

// 套餐卡-分配美容师
export const insbeUpdate = p => post('api/v1.0/indentinfo/indent_card_sbe_update', p)

// 套餐卡分组查询
export const markgrouGet = p => post('api/v1.0/indentinfo/markete_group_json_get', p)

// 套餐卡 查询关联抵扣卡
export const markinCard = p => post('api/v1.0/indentinfo/indent_card_as_ded_get', p)


// 开单-查询
export const billQuery = p => post('api/v1.0/indentinfo/indentinfo_desk_get', p)

// 开单-数量统计
export const billOrderNumber = p => post('api/v1.0/indentinfo/indent_info_num', p)

// 开单-关闭本单
export const billClose = p => post('api/v1.0/indentinfo/indent_info_delete', p)

// 开单-[服务分组]查询
export const billServeGroup = p => post('api/v1.0/indentinfo/serve_group_json_get', p)

// 开单-[服务详情]查询
export const billServeList = p => post('api/v1.0/indentinfo/serve_card_details', p)

// 开单-[商品分组]查询
export const billGoodGroup = p => post('api/v1.0/indentinfo/goods_group_json_get', p)

// 开单-[服务详情]查询
export const billGoodList = p => post('api/v1.0/indentinfo/goods_card_details', p)

// 开单-[用户待使用数据]查询
export const billUserHave = p => post('api/v1.0/indentinfo/indentinfo_use_num', p)

// 开单-[收款添加-开单下单]
export const billCollection = p => post('api/v1.0/indentinfo/indent_info_add_one', p)

// 开单-结账[套餐卡查询]
export const billPackCard = p => post('api/v1.0/indentinfo/user_as_card_get', p)

// 开单-结账[套餐卡关联服务]
export const billCardServe = p => post('api/v1.0/indentinfo/card_serve_detail', p)

// 开单-结账[费用明细]
export const billCostQuery = p => post('api/v1.0/indentinfo/indent_info_cost_detail', p)

// 开单-结账[核销明细]
export const billWhiteQuery = p => post('api/v1.0/indentinfo/indent_info_ver_detail', p)

// 开单-查询[待核销]
export const billWriteOffQuery = p => post('api/v1.0/indentinfo/indent_info_get_id', p)

// 开单-收款结账
export const billCheckOut = p => post('api/v1.0/indentinfo/indent_info_add_two', p)

// 开单-订单-分配美容师
export const billOrderDistribute = p => post('api/v1.0/indentinfo/indent_info_sbe_update', p)

// 开单-订单-详情查询
export const billOrderDetail = p => post('api/v1.0/indentinfo/indent_info_get_detail', p)

// 开单-订单-[代金券查询]
export const billOrderBond = p => post('api/v1.0/marketeinfo/markete_kim_as_code_get', p)

// 开单-订单-[备注添加]
export const indentMark = p => post('api/v1.0/indentinfo/indent_mark_update', p)

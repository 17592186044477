<!-- 开单表单 -->
<template>
  <div class="box100">
    <!-- 开单下单 -->
    <div class="box100 flexCB" v-if="!isSettlement">
      <div class="whiteLeftBg">
        <div class="leftTab flexCB">
          <div class="tabItem" :class="{'tabItemAc':(billType === 1)}" @click="changeType(1)">服务产品</div>
          <div class="tabItem" :class="{'tabItemAc':(billType === 2)}" @click="changeType(2)">商品</div>
        </div>
        <div class="leftCont">
          <!-- 服务 -->
          <div class="box100 flexCB" v-if="billType === 1">
            <div class="leftTabBox">
              <el-menu class="selectMenu"  unique-opened :default-active="serveVal">
                <template v-for="item in serveTab">
                  <el-submenu v-if="item.value&&item.value.length>0" :key="item.id" :index="item.id">
                    <template slot="title">{{ item.name }}</template>
                    <el-menu-item v-for='subItem in item.value' :key='subItem.id' :index="subItem.id" @click="selectVal(subItem)">
                      <template slot="title">{{ subItem.name }}</template>
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item v-else :key="item.id" :index="item.id" @click="selectVal(item)">{{item.name}}</el-menu-item>
                </template>
              </el-menu>
            </div>
            <div class="leftList">
              <!-- 服务列表 -->
              <div class="box100 scrollBox" v-show="showServeList">
                <div class="productItem flexCB" v-for="(serItem, index) in serveList" :key="index" @click="serveAdd(serItem)">
                  <i class="el-icon-circle-plus" style="color: #44B557;cursor: pointer;font-size: 18px;"></i>
                  <div class="proItemInfo flexAC" style="padding-left: 10px;">
                   <img class="proImg" :src="serItem.serveimg"/>
                    <div class="piInfoP">
                      <p class="proTitle">{{ serItem.servename }}</p>
                      <p class="proRebid">{{ serItem.servetlong }}</p>
                      <p class="proDesc">
                        <span v-for="tag in serItem.servelabel" :key="tag">{{ tag }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="priceBox">
                    <p class="newPrice">￥{{ serItem.serveprice }}</p>
                    <p class="oldPrice">￥{{ serItem.lineprice }}</p>
                  </div>
                </div>
              </div>
              <div class="notList" v-show="!showServeList">
                <img src="../../assets/img/noData.png"/>
                <p>暂无数据</p>
              </div>
            </div>
          </div>
          <!-- 商品 -->
          <div class="box100 flexCB" v-if="billType === 2">
            <div class="leftTabBox">
              <el-menu class="selectMenu" unique-opened :default-active="goodsVal">
                <template v-for="item in goodsTab">
                  <el-submenu v-if="item.value&&item.value.length>0" :key="item.id" :index="item.id">
                    <template slot="title">{{ item.name }}</template>
                    <el-menu-item v-for='subItem in item.value' :key='subItem.id' :index="subItem.id" @click="selectGoods(subItem)">
                      <template slot="title">{{ subItem.name }}</template>
                    </el-menu-item>
                  </el-submenu>
                  <el-menu-item v-else :key="item.id" :index="item.id" @click="selectGoods(item)">{{item.name}}</el-menu-item>
                </template>
              </el-menu>
            </div>
            <div class="leftList">
              <!-- 商品 -->
              <div class="box100 scrollBox" v-show="showGoodsList">
                <div class="productItem flexCB" v-for="(goodItem, index) in goodsList" :key="index" @click="serveAdd(goodItem)">
                  <i class="el-icon-circle-plus" style="color: #44B557;cursor: pointer;font-size: 18px;"></i>
                  <div class="proItemInfo flexAC" style="padding-left: 10px;">
                    <img class="proImg" :src="goodItem.furl"/>
                    <div class="piInfoP">
                      <p class="proTitle">{{ goodItem.gdname }}</p>
                      <p class="proRebid" style="margin-top: 40px;">{{ goodItem.gdspec }}/{{ goodItem.guname }}</p>
                    </div>
                  </div>
                  <div class="priceBox"><p class="newPrice">￥{{ goodItem.gdprice }}</p></div>
                </div>
              </div>
              <div class="notList" v-show="!showGoodsList">
                <img src="../../assets/img/noData.png"/>
                <p>暂无数据</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 订单信息 -->
      <div class="whiteRigBg">
        <div class="flexCB" style="padding: 16px 16px;">
          <div class="flexAC">
            <p style="margin-right: 16px;">客户查询</p>
            <el-popover placement="bottom-start" v-model="visible" trigger="manual">
              <div class="userRow" v-for="(item, index) in userList" :key="index" @click="selectUser(item)">{{ item.username }} / {{ item.uphone }}</div>
              <div slot="reference">
                <el-input placeholder="请输入客户手机号/名称" v-model="userKey" @input="handGetList"></el-input>
              </div>
            </el-popover>
          </div>
          <div class="flexCE">
            <el-button @click="handClose">关闭本单</el-button>
            <el-button type="primary" plain @click="handSaveBill(1)">保存本单</el-button>
          </div>
        </div>
        <div class="userBox" style="padding: 16px;">
          <div class="userInfoBox flexF" v-if="showUserInfo">
            <p class="userItem" style="margin: 0px;"><span style="width: 60px;">姓名</span><span class="utValue">{{ userInfo.username }}</span></p>
            <p class="userItem" style="margin: 0px;"><span style="width: 60px;">绑定手机</span><span class="utValue">{{ userInfo.uphone }}</span></p>
            <p class="userItem" style="margin: 0px;cursor: pointer;"><span style="width: 60px;">待使用</span><span class="utValue" style="color: #3C84EF;">{{ buyServeList.length }}</span></p>
          </div>
          <div class="userInfoBox" v-if="!showUserInfo" style="line-height:40px;text-align: center">暂无客户信息</div>
        </div>
        <!-- 选择服务or商品列表 -->
        <div class="selectBox scrollBox">
          <div class="selectList">
            <div class="selectItem flexCB" v-for="(item, index) in selectBillList" :key="index">
              <i class="el-icon-error" @click="handSelectDel(index)" style="color: #F44C8E;cursor: pointer;font-size: 18px;"></i>
              <div class="selInfoItem flexCB">
                <!-- 服务产品 -->
                <template v-if="item.type === 1">
                  <div class="proItemInfo flexAC">
                    <img class="proImg" :src="item.serveimg"/>
                    <div class="piInfoP">
                      <p class="proTitle">{{ item.servename }}</p>
                      <p class="proRebid">{{ item.servetlong }}</p>
                      <p class="proDesc">
                        <span v-for="tag in item.servelabel" :key="tag">{{ tag }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="priceBox">
                    <p class="newPrice">￥{{ item.serveprice }}</p>
                    <p class="oldPrice">￥{{ item.lineprice }}</p>
                  </div>
                </template>
                <!-- 商品  -->
                <template v-if="item.type === 2">
                  <div class="proItemInfo flexAC">
                    <img class="proImg" :src="item.furl"/>
                    <div class="piInfoP">
                      <p class="proTitle">{{ item.gdname }}</p>
                      <p class="proRebid" style="margin-top: 40px;">{{ item.gdspec }}/{{ item.guname }}</p>
                    </div>
                  </div>
                  <div class="priceBox"><p class="newPrice">￥{{ item.gdprice }}</p></div>
                </template>
              </div>
              <div class="distributeBox">
                <div class="disImg" v-if="item.sbeid === ''" @click="handAddBea(index)"><img src="../../assets/img/beautician.png"/> <p>分配美容师</p></div>
                <div class="disCode" v-else>
                  <p class="disCodeNum">{{ item.sbecode }}</p>
                  <div class="flexCB">
                    <p class="disEdit" @click="handAddBea(index)">修改</p>
                    <p class="disDel" @click="handDelBea(item,index)">删除</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="notList" v-show="!selectBillList.length" style="margin: 30% auto;">
            <img src="../../assets/img/noData.png"/>
            <p>暂无数据</p>
          </div>
        </div>
        <div class="payment_foot flexCB">
          <p class="paymentP">数量：<span>{{ selectBillList.length }}</span></p>
          <div class="flexCE">
            <p class="paymentP">合计金额：<span>{{ selectPrice }}</span>元</p>
            <p class="paymentBtn" @click="handSaveBill(2)">收款</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 收款  -->
    <!-- 开单结账 -->
    <div class="box100" v-if="isSettlement">
      <CheckOut :userInfo="userInfo" :indentId="indentId" :editId="editId" @outClose="outClose" @checkOutSuccess="checkOutSuccess"></CheckOut>
    </div>
    <el-dialog title="分配美容师" :visible.sync="beauticianShow" width="1200px" center class="gyDialog" :close-on-click-modal="false" :before-close="dialogClose">
      <Beaut @closeCancel="dialogClose" @selectConfirm="selectConfirm"></Beaut>
    </el-dialog>
  </div>
</template>

<script>
import { billGoodGroup, billGoodList, billServeGroup, billServeList, billUserHave, billCollection, billWriteOffQuery } from '@/api/reception'
import { customerQueryKey } from '@/api/customer'
import Beaut from '@/components/recepion/beaut'
import CheckOut from '@/components/recepion/checkOut'
export default {
  name: 'bill',
  props: ['editId', 'userid'],
  components: {
    Beaut, // 分配美容师
    CheckOut // 立即下单
  },
  data () {
    return {
      isSettlement: false,
      billType: 1,
      serveTab: [],
      serveVal: '',
      serveLevel: '',
      serveList: [],
      showServeList: false,
      goodsTab: [],
      goodsVal: '',
      goodsLevel: '',
      showGoodsList: false,
      goodsList: [],
      userKey: '',
      userList: [], // 用户列表
      visible: false,
      showUserInfo: false,
      userInfo: '',
      selectBillList: [],
      buyServeList: [],
      beauticianShow: false,
      beauticianIndex: '',
      selectPrice: 0,
      indentId: '', // 订单id
      editableId: ''
    }
  },
  watch: {
    editId (val) {
      if (val !== null) {
        this.editableId = val
        this.getOrderInfo()
      }
    },
    userid (val) {
      this.userid = val
      this.getUserInfo(val)
    }
  },
  created () {
    this.getServeTab()
    if (this.editId !== null) {
      this.editableId = this.editId
      this.getOrderInfo()
    }
    if (this.userid !== null) {
      this.getUserInfo(this.userid)
    }
  },
  methods: {
    changeType (type) {
      this.billType = type
      if (type === 2) {
        this.getGoodsTab()
      }
    },
    // 服务产品tab
    getServeTab () {
      billServeGroup().then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            this.serveTab = res.data.data
            this.serveVal = this.serveTab[0].id
            this.serveLevel = this.serveTab[0].level
            this.getServeList()
          }
        }
      })
    },
    // 服务产品tab[切换]
    selectVal (item) {
      this.serveVal = item.id
      this.serveLevel = item.level
      this.getServeList()
    },
    // 服务产品列表
    getServeList () {
      billServeList({ id: this.serveVal, level: this.serveLevel }).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            this.serveList = res.data.data
            this.showServeList = true
          } else {
            this.showServeList = false
          }
        }
      })
    },
    // 商品tab
    getGoodsTab () {
      billGoodGroup().then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            this.goodsTab = res.data.data
            this.goodsVal = this.goodsTab[0].id
            this.goodsLevel = this.goodsTab[0].level
            this.getGoodsList()
          }
        }
      })
    },
    // 商品切换
    selectGoods (item) {
      this.goodsVal = item.id
      this.goodsLevel = item.level
      this.getGoodsList()
    },
    // 商品列表
    getGoodsList () {
      billGoodList({ id: this.goodsVal, level: this.goodsLevel }).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            this.goodsList = res.data.data
            this.showGoodsList = true
          } else {
            this.showGoodsList = false
          }
        }
      })
    },
    // [待核销] 订单信息
    getOrderInfo () {
      billWriteOffQuery({ indentid: this.editableId }).then(res => {
        if (res.status === 1) {
          this.selectBillList = res.data.sg_json
          this.selectPrice = Number(res.data.indentmoney)
          if (res.data.userid !== '') {
            const obj = {
              userid: res.data.userid,
              username: res.data.username,
              uphone: res.data.uphone,
              balance: res.data.balance,
              points: res.data.points,
              udphoto: res.data.udphoto
            }
            this.showUserInfo = true
            this.userInfo = obj
            this.getUserBuy(this.userInfo.userid)
          }
        }
      })
    },
    /* 从预约-已到店 查询用户信息 */
    getUserInfo (val) {
      customerQueryKey({ username: val }).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            const obj = {
              userid: res.data.data[0].userid,
              username: res.data.data[0].username,
              uphone: res.data.data[0].uphone,
              balance: res.data.data[0].balance,
              points: res.data.data[0].points,
              udphoto: res.data.data[0].udphoto
            }
            this.showUserInfo = true
            this.userInfo = obj
            this.getUserBuy(this.userInfo.userid)
          }
        }
      })
    },
    // 用户列表
    handGetList (val) {
      if (val !== '') {
        customerQueryKey({ username: val }).then(res => {
          if (res.status === 1) {
            if (res.data.data.length > 0) {
              this.userList = res.data.data
              this.visible = true
            } else {
              this.userList = []
              this.visible = false
            }
          }
        })
      } else {
        this.userList = []
        this.visible = false
      }
    },
    // 选择用户
    selectUser (item) {
      this.visible = false
      this.showUserInfo = true
      this.userKey = ''
      // console.log(item)
      this.userInfo = { ...item }
      this.getUserBuy(item.userid)
    },
    getUserBuy (id) {
      billUserHave({ userid: id }).then(res => {
        if (res.status === 1) {
          this.buyServeList = res.data.data
        }
      })
    },
    // 点击添加 [服务or商品]
    serveAdd (item) {
      item.sbeid = ''
      item.sbecode = ''
      this.selectBillList.push(JSON.parse(JSON.stringify(item)))
      this.selectPrice = this.getPrice(this.selectBillList)
      // console.log(this.selectBillList)
    },
    // 点击删除 [服务or商品]
    handSelectDel (index) {
      this.selectBillList.splice(index, 1)
      this.selectPrice = this.getPrice(this.selectBillList)
    },
    getPrice (list) {
      let num = 0
      list.forEach((item) => {
        if (item.type === 1) {
          num += Number(item.serveprice)
        } else if (item.type === 2) {
          num += Number(item.gdprice)
        }
      })
      return num.toFixed(2)
    },
    // 分配美容师[展示]
    handAddBea (eq) {
      this.beauticianShow = true
      this.beauticianIndex = eq
    },
    dialogClose () { this.beauticianShow = false },
    // 分配美容师 [确定]
    selectConfirm (res) {
      this.beauticianShow = false
      this.selectBillList[this.beauticianIndex].sbeid = res.sbeid
      this.selectBillList[this.beauticianIndex].sbecode = res.sbecode
    },
    // 分配美容师[删除]
    handDelBea (item, eq) {
      this.selectBillList[eq].sbeid = ''
      this.selectBillList[eq].sbecode = ''
      this.$set(this.selectBillList, eq, item)
    },
    // 关闭本单
    handClose () {
      this.$emit('closeBill')
    },
    // 收款下单[保存本单]
    handSaveBill (type) {
      if (this.selectBillList.length === 0) {
        this.$message.warning('请选择服务项目')
      } else {
        const paramsArr = []
        this.selectBillList.forEach((item) => {
          let _id = ''
          if (item.type === 1) {
            _id = item.serveid
          } else {
            _id = item.gdid
          }
          const obj = { id: _id, type: item.type, sbeid: item.sbeid }
          paramsArr.push(obj)
        })
        const params = {
          userid: this.userInfo.userid,
          indentmoney: this.selectPrice,
          indentid: this.editableId,
          sg_json: paramsArr
        }
        billCollection(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            if (type === 1) { // 保存本单
              this.$emit('saveBill')
            } else if (type === 2) { // 收款
              this.isSettlement = true
              this.indentId = res.data.indentid
            }
          } else {
            this.$message.error(res.message)
          }
        })
      }
    },
    // 返回上一级【结账页面】
    outClose (res) {
      if (res !== null) {
        this.editableId = res
        this.getOrderInfo()
      }
      this.isSettlement = false
    },
    // 结算成功
    checkOutSuccess () {
      this.$emit('paySuccess')
    }
  }
}
</script>

<style scoped>
.tabItem{
  width: 49%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  background: #F7F9FB;
  cursor: pointer;
}
.tabItemAc{
  background: #3C84EF;
  color: #fff;
}
.leftCont{
  width: 100%;
  height: calc(100% - 40px);
  padding-top: 16px;
  box-sizing: border-box;
}
.leftTabBox{
  width: 140px;
  height: 100%;
  overflow: hidden;
}
.leftList{
  width: calc(100% - 156px);
  height: 100%;
  position: relative;
}
.selectBox{
  width: 100%;
  height: calc(100% - 250px);
  position: relative;
  padding: 0px 16px;
  box-sizing: border-box;
}
.selectItem{
  margin-top: 16px;
}
.selInfoItem{
  width: calc(100% - 180px);
  height: 96px;
  border-radius: 4px;
  border: 1px solid #A5B7CC;
  padding: 8px 16px 8px 8px;
  cursor: pointer;
  box-sizing: border-box;
}
</style>

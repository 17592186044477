<!-- 开单结账 -->
<template>
  <div class="box100 flexCB">
    <div class="whiteLeftBg">
      <!-- 用户信息 -->
      <p class="formTitle" style="padding: 10px 16px 0px;box-sizing: border-box;">用户信息</p>
      <div class="userInfoBox flexF" v-if="haveUser">
        <p class="userItem"><span style="width: 60px;">姓名</span><span class="utValue">{{ userInfo.username }}</span></p>
        <p class="userItem"><span style="width: 60px;">绑定手机</span><span class="utValue">{{ userInfo.uphone }}</span></p>
        <p class="userItem"><span style="width: 60px;">账户余额</span><span class="utValue">{{ userInfo.balance }}</span></p>
        <p class="userItem"><span style="width: 60px;">账号积分</span><span class="utValue">{{ userInfo.points }}</span></p>
        <p class="userItem" v-if="userInfo.udphoto !== ''"><span style="width: 60px;">照片</span>
          <span style="color: #3C84EF;height: 40px;cursor: pointer;" @click="showUserImg">[点击查看]</span>
        </p>
      </div>
      <div class="userInfoBox" v-if="!haveUser" style="line-height: 120px;text-align: center">暂无用户信息</div>
      <!-- 套餐卡列表 -->
      <div class="userCardBox scrollBox">
        <div style="display: flex;align-items: center;margin-top: 16px;">
          <label>代金券绑定</label>
          <el-popover placement="bottom-start" v-model="visibleShow" trigger="manual" style="width: 200px;margin: 0 8px;">
            <div class="userRow" v-for="(item, index) in cashList" :key="index" @click="selectCash(item)">
              {{ item.code }}
            </div>
            <div slot="reference">
              <el-input placeholder="请输入代金券编号" v-model="bondKey" @input="handGetList"></el-input>
            </div>
          </el-popover>
        </div>
        <div class="box100" v-if="haveCard">
          <!-- 套餐卡 -->
          <template>
            <p class="formTitle" style="margin: 16px 0 0;">套餐卡</p>
            <div class="flexF">
              <div class="packCard flexCB use" v-for="(item, index) in cardList" :key="index"
                   :class="item.is_ok === true ? 'use': 'noUse'" @click.stop="handCardDetail(item)">
                <div class="pcLeft">
                  <p class="cardWorth" :class="item.mcatype === 1 ? '': 'notNum'">¥ {{ item.mcaprice }}</p>
                  <p class="cardSur" v-if="item.mcatype === 1" style="font-size: 12px;">剩余<span>{{ item.remain }}</span>/{{ item.mcacount }}</p>
                </div>
                <div class="pcRig">
                  <p class="cardTitle">{{ item.mcaname }}</p>
                  <p class="cardDes">{{ item.mcacontent }}</p>
                  <p class="cardDate">有效期 {{ item.mcaeffetime }}</p>
                  <p @click.stop="" v-if="item.is_ok === true" >
                    <el-checkbox class="useCheck" v-model="item.checked" @change="handCheckOne(item, index)"></el-checkbox>
                  </p>
                </div>
              </div>
            </div>
          </template>
          <!-- 抵扣券 -->
          <template v-if="bondList.length > 0">
            <p class="formTitle" style="margin: 16px 0 0;">抵扣券</p>
            <div class="flexF">
              <div class="packCard flexCB" v-for="(item, index) in bondList" :key="index"
                   :class="item.is_ok === true ? 'use': 'noUse'" @click="handCardDetail(item)">
                <div class="packBond">
                  <p class="cardWorth">¥ {{ item.mdprice }}</p>
                  <p class="bondDesc">赠送价值</p>
                </div>
                <div class="pcRig">
                  <p class="cardTitle">{{ item.mdname }}</p>
                  <p class="cardDes">{{ item.mdmark }}</p>
                  <p class="cardDate">有效期 {{ item.mdeffetime }}</p>
                  <p @click.stop="" v-if="item.is_ok === true" >
                    <el-checkbox class="useCheck" v-model="item.checked" @change="handCheckTwo(item, index)"></el-checkbox>
                  </p>
                </div>
              </div>
            </div>
          </template>
          <!-- 代金券 -->
          <template v-if="couponList.length > 0">
            <p class="formTitle" style="margin: 16px 0 0;">代金券</p>
            <div class="flexF">
              <div class="packCard flexCB" v-for="(item, index) in couponList" :key="index"
                   :class="item.is_ok === true ? 'use': 'noUse'" @click="handCardDetail(item)">
                <div class="coupon">
                  <p class="cardWorth">¥ {{ item.mkprice }}</p>
                  <p class="bondDesc">详细信息</p>
                </div>
                <div class="pcRig">
                  <p class="cardTitle">{{ item.mkname }}</p>
                  <p class="cardDes">{{ item.mkmark }}</p>
                  <p class="cardDate">有效期 {{ item.mdeffetime }}</p>
                  <p @click.stop="" v-if="item.is_ok === true" >
                    <el-checkbox class="useCheck" v-model="item.checked" @change="handCheckTh(item, index)"></el-checkbox>
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="notList" style="margin: 30% auto;" v-if="!haveCard">
          <img src="../../assets/img/noData.png"/>
          <p>暂无数据</p>
        </div>
      </div>
    </div>
    <div class="whiteRigBg">
      <!-- 费用明细 -->
      <div class="costDetail">
        <p class="formTitle">费用明细</p>
        <div class="flexCB cwRow" v-for="(item, index) in costList" :key="index">
          <p>{{ item.name }}<span></span></p>
          <p>{{ item.price }}元</p>
        </div>
      </div>
      <!-- 核销明细 -->
      <div class="writeDetail">
        <p class="formTitle">核销明细</p>
        <div class="writeBox" v-show="haveWhite">
          <div class="flexCB cwRow" v-for="(item, index) in whiteList" :key="index">
            <p><i class="el-icon-error" @click="handDel(item, index)"></i>{{ item.mark }}<span></span></p>
            <p>- {{ item.price }}元</p>
          </div>
        </div>
       <div v-show="!haveWhite" style="line-height: 60px;text-align: center;">暂无优惠</div>
      </div>
      <!-- 收款金额 -->
      <div class="totalDetail flexCE">
        <p>待收款：<span>{{ priceTotal }}元</span></p>
      </div>
      <div class="payment_foot" style="height: 160px;">
        <el-input class="formTextarea" v-model="indmark" type="textarea" maxlength="200" placeholder="请输备注内容（最多200字）"></el-input>
        <div class="flexCB" style="margin-top: 10px;">
          <div class="flexCE">
            <p style="margin-right: 8px;">支付方式</p>
            <el-radio v-model="payMethod" label="1">微信支付</el-radio>
            <el-radio v-model="payMethod" label="3">余额支付</el-radio>
            <el-radio v-model="payMethod" label="5">现金支付</el-radio>
          </div>
          <div class="flexCE">
            <div class="settingCancel" @click="handBack">返回上一级</div>
            <p class="paymentBtn" @click="handCheckOut">结账</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 关联服务 -->
    <el-dialog title="关联服务" :visible.sync="showServe" width="600px" center class="gyDialog">
      <p class="userItem" v-if="couShow" style="width: 100%;">
        <span style="width: 60px;">券编号</span>
        <span class="utValue" style="width: 40%;">{{ couCode }}</span>
      </p>
      <el-table
        :data="serveList"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="服务名称"
          prop="servename">
        </el-table-column>
        <el-table-column
          label="剩余数量"
          prop="icsnum">
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="用户图像" :visible.sync="showImg" width="600px" center class="gyDialog">
      <div style="text-align: center">
        <img :src="userInfo.udphoto" style="width: 100%;">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { billCostQuery, billWhiteQuery, billPackCard, billCardServe, billCheckOut, billOrderBond } from '@/api/reception'

export default {
  name: 'checkOut',
  props: ['userInfo', 'indentId', 'editId'],
  data () {
    return {
      payMethod: '1',
      haveUser: false,
      costList: [], // 费用明细
      uacidList: [], // 套餐卡选择id 集合
      whiteList: [], // 核销明细
      haveWhite: false,
      haveCard: false,
      cardList: [],
      bondList: [],
      couponList: [],
      priceTotal: 0,
      showServe: false,
      serveList: [],
      couCode: '',
      couShow: false,
      bondKey: '',
      visibleShow: false,
      cashList: [],
      showImg: false,
      cardArr: [],
      bondArr: [],
      coupArr: [],
      mkacid: '',
      indmark: ''
    }
  },
  created () {
    if (this.userInfo === '') {
      this.haveUser = false
    } else {
      this.haveUser = true
      this.getSetMeal() // 套餐卡
    }
    this.getCostDetail()
  },
  methods: {
    showUserImg () {
      this.showImg = true
    },
    // 费用明细
    getCostDetail () {
      billCostQuery({ indentid: this.indentId }).then(res => {
        if (res.status === 1) {
          this.costList = res.data.data
          this.getWhiteDetail()
        }
      })
    },
    // 核销明细
    getWhiteDetail () {
      billWhiteQuery({ indentid: this.indentId, userid: this.userInfo.userid, uacid: this.uacidList, mkacid: this.mkacid }).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            this.whiteList = res.data.data
            this.haveWhite = true
          } else {
            this.whiteList = []
            this.haveWhite = false
          }
          this.getTotal()
        }
      })
    },
    // 套餐卡查询
    getSetMeal () {
      billPackCard({ userid: this.userInfo.userid, indentid: this.indentId }).then(res => {
        if (res.status === 1) {
          if (res.data.card.length === 0 && res.data.ded.length === 0 && res.data.kim.length === 0) {
            this.haveCard = false
          } else {
            this.haveCard = true
            this.cardList = res.data.card
            this.bondList = res.data.ded
            this.couponList = res.data.kim
            if (this.cardList.length > 0) {
              this.cardList.forEach((item) => {
                item.checked = false
              })
            }
            if (this.bondList.length > 0) {
              this.bondList.forEach((item) => {
                item.checked = false
              })
            }
            if (this.couponList.length > 0) {
              this.couponList.forEach((item) => {
                item.checked = false
              })
            }
          }
        }
      })
    },
    // 选择套餐卡
    handCheckOne (val, eq) {
      const selectArr = []
      this.$set(this.cardList, eq, val)
      this.cardList.forEach((item, index) => {
        if (item.checked === true) {
          selectArr.push({ id: item.id, type: item.type })
        } else if (item.checked === false) {
          selectArr.splice(index, 1)
        }
      })
      this.cardArr = selectArr
      this.uacidList = [...this.cardArr, ...this.bondArr, ...this.coupArr]
      this.getWhiteDetail()
    },
    // 选择抵扣券
    handCheckTwo (val, eq) {
      const selectArr = []
      this.$set(this.bondList, eq, val)
      this.bondList.forEach((item, index) => {
        if (item.checked === true) {
          selectArr.push({ id: item.id, type: item.type })
        } else if (item.checked === false) {
          selectArr.splice(index, 1)
        }
      })
      this.bondArr = selectArr
      this.uacidList = [...this.cardArr, ...this.bondArr, ...this.coupArr]
      this.getWhiteDetail()
    },
    // 选择代金券
    handCheckTh (val, eq) {
      const selectArr = []
      this.$set(this.couponList, eq, val)
      this.couponList.forEach((item, index) => {
        if (item.checked === true) {
          selectArr.push({ id: item.id, type: item.type })
        } else if (item.checked === false) {
          selectArr.splice(index, 1)
        }
      })
      this.coupArr = selectArr
      this.uacidList = [...this.cardArr, ...this.bondArr, ...this.coupArr]
      this.getWhiteDetail()
    },
    // 点击删除优惠
    handDel (item, eq) {
      this.whiteList.splice(eq, 1)
      if (this.whiteList.length === 0) {
        this.haveWhite = false
      }
      this.getTotal()
    },
    // 查询【代金券】列表
    handGetList (val) {
      if (val !== '') {
        if (val.length > 5) {
          billOrderBond({ code: val }).then(res => {
            if (res.status === 1) {
              if (res.data.data.length > 0) {
                this.cashList = res.data.data
                this.visibleShow = true
              } else {
                this.cashList = []
                this.visibleShow = false
              }
            }
          })
        }
      }
    },
    // 点击选择【代金券查询】结果
    selectCash (item) {
      if (item.state === true) {
        this.$message.warning('该抵扣券已被使用')
      } else {
        this.visibleShow = false
        this.bondKey = item.code
        this.mkacid = item.mkacid
        this.getWhiteDetail()
      }
    },
    // 总收款
    getTotal () {
      let num = 0
      this.costList.forEach((item) => {
        num += Number(item.price)
      })
      this.whiteList.forEach((item) => {
        num -= Number(item.price)
      })
      this.priceTotal = parseFloat(num).toFixed(2)
    },
    // 返回上一级
    handBack () {
      if (this.editId === null) { // [新下单]返回上级
        this.$emit('outClose', this.indentId)
      } else { // [已下单] 返回上级
        this.$emit('outClose', null)
      }
    },
    // 套餐卡-抵扣券-优惠券 绑定服务
    handCardDetail (row) {
      this.showServe = true
      if (row.type === 3) {
        this.couCode = row.mkcode
        this.couShow = true
      } else {
        this.couShow = false
      }
      billCardServe({ userid: this.userInfo.userid, id: row.id, type: row.type }).then(res => {
        if (res.status === 1) {
          this.serveList = res.data.data
        }
      })
    },
    // 订单结账
    handCheckOut () {
      const params = {
        indentid: this.indentId,
        sysid: this.payMethod,
        afterver: this.whiteList,
        paymoney: this.priceTotal,
        indmark: this.indmark
      }
      billCheckOut(params).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.$emit('checkOutSuccess')
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.whiteLeftBg{
  padding: 0px;
}
.userInfoBox{
  height: 120px;
  border-bottom: 8px solid #F7F9FB;
  padding: 0px 16px;
  box-sizing: border-box;
}
.userCardBox{
  width: 100%;
  height: calc(100% - 185px);
  position: relative;
  padding: 0px 16px 16px;
  box-sizing: border-box;
}
.packCard{
  width: 48%;
  height: 96px;
  margin-right: 3%;
  border-radius: 4px;
  border: 1px solid #A5B7CC;
  margin-top: 16px;
  box-sizing: border-box;
  cursor: pointer;
}
.packCard:nth-child(even){
  margin-right: 0;
}
.pcLeft,.packBond,.coupon{
  width: 110px;
  height: 100%;
  text-align: center;
}
.pcRig{
  width: calc(100% - 110px);
  height: 100%;
  padding: 12px 16px;
  box-sizing: border-box;
  position: relative;
}
.bondDesc{
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
}
.ponCode{
  text-align: left;
  color: #FCCDE0;
  margin: 8px 0 10px;
  font-size: 12px;
  padding-left: 8px;
}
/* 套餐卡 背景色 */
.use .pcLeft{
  background: linear-gradient(131deg, #14151D 0%, #212433 100%);
}
/* 代金券 背景色 */
.use .packBond{
  background: linear-gradient(134deg, #2075F5 0%, #63A1FE 100%);
  color: #fff;
}
/* 代金券 背景色 */
.use .coupon{
  background: linear-gradient(133deg, #F44C8E 0%, #FB82B1 100%);
  color: #fff;
}
/* 不能使用 背景色 */
.noUse .pcLeft,
.noUse .packBond,
.noUse .coupon{ background: #DDDDDD; }
.cardWorth{
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
}
.notNum{
  margin-top: 35px;
}
.use .pcLeft .cardWorth{ color: #FCDEA0; }
.use .packBond .cardWorth{ color: #ffffff; }
/* 不能使用 noUse 字体颜色 */
.noUse .pcRig,
.noUse .pcLeft .cardWorth,
.noUse .packBond .cardWorth,
.noUse .coupon .cardWorth,
.noUse .bondDesc,
.noUse .coupon .ponCode{ color: #999; }
.cardSur{
  width: 80px;
  height: 20px;
  line-height: 20px;
  border-radius: 11px;
  margin: 5px auto;
}
.use .cardSur{
  border: 1px solid #F5AB76;
  color: #F5AB76;
}
.noUse .cardSur{
  border: 1px solid #999;
  color: #999;
}
.use .cardSur span{ color: #FCDEA0; }
.noUse .cardSur span{ color: #999999; }
.cardTitle{
  font-weight: 600;
}
.cardDes,.cardDate{
  color: #999;
  margin-top: 6px;
}
.cardDes{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.useCheck{
  position: absolute;
  right: 10px;
  top: 40px;
}
.costDetail,.writeDetail,.totalDetail{
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
}
.costDetail{
  border-bottom: 8px solid #F7F9FB;
  min-height: 150px;
}
.writeDetail{
  min-height: 150px;
  border-bottom: 1px solid #F3F4F9;
}
.cwRow{
  margin-top: 8px;
  color: #576271;
}
.cwRow p span{
  color: #A5B7CC;
}
.cwRow p i{
  color: #F44C8E;
  margin-right: 8px;
  cursor: pointer;
}
.totalDetail p{
  font-size: 20px;
  color: #576271;
}
.totalDetail p span{
  color: #FB4B2F;
}
</style>

<!-- 分配美容师 -->
<template>
  <div class="box100">
    <div class="dis_top flexCB"  v-if="showUserInfo">
      <p class="userItem" style="margin-bottom: 0px;">
        <span style="width: 60px;">姓名</span>
        <span class="utValue">{{ userInformation.username }}</span>
      </p>
      <p class="userItem" style="margin-bottom: 0px;">
        <span style="width: 60px;">电话</span>
        <span class="utValue">{{ userInformation.uphone }}</span>
      </p>
      <p class="userItem disBack" style="margin-bottom: 0px;" @click="handBack">
        <i class="el-icon-arrow-left"></i><span>返回</span>
      </p>
    </div>
    <div class="dis_top" v-if="!showUserInfo" style="line-height:40px;text-align: center">暂无客户信息</div>
    <div class="dis_info scrollBox">
      <div class="selectItem flexCB" v-for="(item, index) in singleList" :key="index">
        <div class="selInfoItem flexCB">
          <!-- 服务产品 -->
          <template v-if="item.type === 1">
            <div class="proItemInfo flexAC">
              <img class="proImg" :src="item.serveimg"/>
              <div class="piInfoP">
                <p class="proTitle">{{ item.servename }}</p>
                <p class="proRebid">{{ item.servetlong }}</p>
                <p class="proDesc">
                  <span v-for="tag in item.servelabel" :key="tag">{{ tag }}</span>
                </p>
              </div>
            </div>
            <div class="priceBox">
              <p class="newPrice">￥{{ item.serveprice }}</p>
              <p class="oldPrice">￥{{ item.lineprice }}</p>
            </div>
          </template>
          <template v-if="item.type === 2">
            <div class="proItemInfo flexAC">
              <img class="proImg" :src="item.furl"/>
              <div class="piInfoP">
                <p class="proTitle">{{ item.gdname }}</p>
                <p class="proRebid" style="margin-top: 40px;">{{ item.gdspec }}/{{ item.guname }}</p>
              </div>
            </div>
            <div class="priceBox"><p class="newPrice">￥{{ item.gdprice }}</p></div>
          </template>
        </div>
        <div class="distributeBox">
          <div class="disImg" v-if="item.sbeid === ''" @click="handAddBea(index)"><img src="../../assets/img/beautician.png"/> <p>分配美容师</p></div>
          <div class="disCode" v-else>
            <p class="disCodeNum">{{ item.sbecode }}</p>
            <div class="flexCB">
              <p class="disEdit" @click="handAddBea(index)">修改</p>
              <p class="disDel" @click="handDelBea(item,index)">删除</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flexCE" style="position: absolute;bottom: 20px;right: 50px;">
      <div class="settingCancel" v-if="!showUserInfo"  @click="handBack">返回</div>
      <div class="settingConfirm" @click="disConfirm">确定</div>
    </div>

    <el-dialog title="分配美容师" :visible.sync="beaShow" width="1200px" center class="gyDialog" :close-on-click-modal="false" :before-close="dialogClose">
      <Beaut @closeCancel="dialogClose" @selectConfirm="selectConfirm"></Beaut>
    </el-dialog>
  </div>
</template>

<script>
import { billWriteOffQuery, billOrderDistribute } from '@/api/reception'
import Beaut from '@/components/recepion/beaut'
export default {
  name: 'distribute',
  props: ['editId'],
  components: {
    Beaut
  },
  data () {
    return {
      singleList: [],
      showUserInfo: false,
      userInformation: '',
      beaShow: false,
      beaIndex: 0
    }
  },
  created () {
    if (this.editId !== null) {
      this.getOrderInfo()
    }
  },
  methods: {
    // [分配美容师] 订单信息
    getOrderInfo () {
      billWriteOffQuery({ indentid: this.editId }).then(res => {
        if (res.status === 1) {
          this.singleList = res.data.sg_json
          if (res.data.userid !== '') {
            const obj = {
              userid: res.data.userid,
              username: res.data.username,
              uphone: res.data.uphone
            }
            this.showUserInfo = true
            this.userInformation = obj
          }
        }
      })
    },
    // 分配美容师[展示]
    handAddBea (eq) {
      this.beaShow = true
      this.beaIndex = eq
    },
    // 分配美容师[关闭]
    dialogClose () {
      this.beaShow = false
    },
    // 分配美容师[选择成功]
    selectConfirm (res) {
      this.beaShow = false
      this.singleList[this.beaIndex].sbeid = res.sbeid
      this.singleList[this.beaIndex].sbecode = res.sbecode
    },
    // 分配美容师[删除]
    handDelBea (item, eq) {
      this.singleList[eq].sbeid = ''
      this.singleList[eq].sbecode = ''
      this.$set(this.singleList, eq, item)
    },
    // 分配美容师[提交]
    disConfirm () {
      const paramsArr = []
      this.singleList.forEach((item) => {
        const obj = { id: item.id, type: item.type, sbeid: item.sbeid }
        paramsArr.push(obj)
      })
      const params = {
        indentid: this.editId,
        sg_json: paramsArr
      }
      billOrderDistribute(params).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.$emit('distriSuccess')
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handBack () {
      this.$emit('distriBack')
    }
  }
}
</script>

<style scoped>
.dis_top{
  font-size: 14px;
  padding: 16px 50px;
  box-sizing: border-box;
  border-bottom: 8px solid #F7F9FB;
}
.disBack{
  text-align: right;
  cursor: pointer;
}
.dis_info{
  width: 100%;
  padding: 16px 50px 0px;
  box-sizing: border-box;
  font-size: 14px;
  height: calc(100% - 140px);
}
.selectItem{
  margin-top: 16px;
}
.selInfoItem{
  width: calc(100% - 180px);
  height: 96px;
  border-radius: 4px;
  border: 1px solid #A5B7CC;
  padding: 8px 16px 8px 8px;
  cursor: pointer;
  box-sizing: border-box;
}
</style>

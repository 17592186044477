import { post } from '@/http/http'

// 客户列表查询
export const customerQuery = p => post('api/v1.0/userinfo/user_info_get', p)

// 客户[消费记录]列表
export const customerConsumeList = p => post('api/v1.0/userinfo/user_expense_get', p)

// 客户[消费记录]详情
export const customerConsumeDetail = p => post('api/v1.0/userinfo/user_expense_get_id', p)

// 客户列表查询
export const customerQueryKey = p => post('api/v1.0/userinfo/user_info_get_id', p)

// 客户[客户分群]查询
export const customerGroupQuery = p => post('api/v1.0/userinfo/user_group_get', p)

// 客户[客户分群]新增
export const customerGroupAdd = p => post('api/v1.0/userinfo/user_group_add', p)

// 客户[客户分群]编辑
export const customerGroupEdit = p => post('api/v1.0/userinfo/user_group_update', p)

// 客户[客户分群]删除
export const customerGroupDel = p => post('api/v1.0/userinfo/user_group_delete', p)

// 客户[上传资料]添加
export const customerUpAdd = p => post('api/v1.0/userinfo/user_datum_add', p)

// 客户[上传资料]查询
export const customerUpQuery = p => post('api/v1.0/userinfo/user_datum_get', p)

// 客户[备注记录]添加
export const customerRemarkAdd = p => post('api/v1.0/userinfo/user_mark_add', p)

// 客户[备注记录]查询
export const customerRemarkQu = p => post('api/v1.0/userinfo/user_mark_get', p)

// 客户[套餐卡]查询 
export const customerCarGet = p => post('api/v1.0/userinfo/user_card_get', p)

// 客户[套餐卡]激活/冻结 
export const customerCarUp = p => post('api/v1.0/userinfo/user_card_up', p)
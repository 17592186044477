<template>
  <div class="box100">
    <div class="box100 billList" v-if="pageType === 1">
      <div class="reception_top flexCB">
        <div class="top_Type flexCB">
          <div class="rnTop_icon ico1">
            <img src="@/assets/img/top_yuyue.png" alt=""> <p class="icon_tit tit3">预约</p>
          </div>
          <div class="rnTop_num ico1 tit3">{{ orderAll.reserve_yy_num }}</div>
        </div>
        <div class="top_Type flexCB">
          <div class="rnTop_icon ico2">
            <img src="@/assets/img/top_hexiao.png" alt=""><p class="icon_tit tit1">待核销</p>
          </div>
          <div class="rnTop_num ico2 tit1">{{ orderAll.reserve_hx_num }}</div>
        </div>
        <div class="top_Type flexCB">
          <div class="rnTop_icon ico3">
            <img src="@/assets/img/top_fenpei.png" alt=""><p class="icon_tit tit1">待分配</p>
          </div>
          <div class="rnTop_num ico3 tit1">{{ orderAll.reserve_fp_num }}</div>
        </div>
        <div class="top_Type flexCB">
          <div class="rnTop_icon ico4">
            <img src="@/assets/img/yiwancheng.png" alt=""><p class="icon_tit tit4">已完成</p>
          </div>
          <div class="rnTop_num ico4 tit4">{{ orderAll.reserve_wc_num }}</div>
        </div>
      </div>
      <div class="reception_fot">
        <div class="flexCB">
          <template v-if="roleBtn[0].power">
            <div class="settingAdd" @click="handBill"><span>立即开单</span></div>
          </template>
          <template v-if="roleBtn[3].power">
            <el-button class="btnReset" style="width: 100px;" @click="handComplete">已完成订单</el-button>
          </template>
        </div>
        <div class="flexAC" style="margin: 16px 0px;">
          <el-input v-model="searchKey"  placeholder="姓名/手机号" class="inputSearch"></el-input>
          <el-select v-model="keyType" placeholder="状态" class="retSelect">
            <el-option v-for="item in stateType" :key="item.index" :label="item.name" :value="item.value"></el-option>
          </el-select>
          <el-date-picker v-model="searchDate" type="daterange" value-format="yyyy-MM-dd" class="searchDate"
                          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
          <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
          <el-button class="btnReset" @click="handleReset">重置</el-button>
        </div>
        <div class="billCard flexF scrollBox">
          <el-card class="orderCard" v-for="(item, index) in orderList" :key="index">
            <div class="flexCB cardSr">
              <p>{{ item.creatime }}</p>
              <p>
                <template v-if="roleBtn[0].power">
                  <span v-if="item.indentstate === 1" style="color: #3C84EF;cursor: pointer;" @click="handWrite(item)">待核销</span>
                </template>
                <span v-if="item.indentstate === 2">待分配</span>
              </p>
            </div>
            <div class="billCardInfo">
              <p><span>订单号:</span>{{ item.indentid }}</p>
              <p><span>客户信息:</span>{{ item.username }}/{{ item.uphone }}</p>
              <p v-for="(tag, eq) in item.serve_name" :key="eq"><span>消费内容:</span>{{ tag }}</p>
            </div>
            <div class="flexCB cardSr">
              <p>共{{ item.num }}项</p>
              <p>合计：<span style="color: #333;">{{ item.price }}</span></p>
            </div>
            <div class="flexCE billCardBtn">
<!--              <p v-if="item.indmark === ''" @click="handRemarks(item)">备注</p>-->
              <p v-if="item.indentstate === 2" @click="handDetail(item)">详情</p>
              <template v-if="roleBtn[1].power">
                <p style="color: #F44C8E;" v-if="item.indentstate === 1" @click="handClose(item.indentid)">删除本单</p>
              </template>
              <template v-if="roleBtn[2].power">
                <p v-if="item.is_ok === false && item.indentstate === 2" @click="handDistribute(item)">分配美容师</p>
              </template>
            </div>
          </el-card>
          <div class="notList" style="margin: 10% auto;" v-if="!orderList.length">
            <img src="../../assets/img/noData.png"/>
            <p>暂无数据</p>
          </div>
        </div>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage" style="margin-top: 0px;">
        </el-pagination>
      </div>
    </div>

    <!-- 开单 -->
    <div class="whiteBg" v-if="pageType === 2" style="padding: 0px;">
      <Bill :editId="editId" :userid="userid" @closeBill="closeBill" @saveBill="saveBill" @paySuccess="paySuccess"></Bill>
    </div>

    <!-- 完成订单 -->
    <div class="whiteBg" v-if="pageType === 3">
      <FinishOrder @finishBack="finishBack" @finishDetail="finishDetail" @handRemarks="handRemarks"></FinishOrder>
    </div>

    <!-- 订单详情 -->
    <div class="detailForm" v-if="pageType === 4">
      <BillDetail :editId="editId" :type="detailType" @detailBack="detailBack"></BillDetail>
    </div>

    <!--  分配美容师  -->
    <div class="detailForm" v-if="pageType === 5" style="padding: 0px;">
      <Distribute :editId="editId" @distriBack="distriBack" @distriSuccess="distriSuccess"></Distribute>
    </div>

    <!-- 备注   -->
    <el-dialog title="备注" :visible.sync="showRek" width="660px" center class="gyDialog" :close-on-click-modal="false" :before-close="markCancel">
      <el-input class="formTextarea" v-model="indmark" type="textarea" maxlength="200" placeholder="请输备注内容（最多200字）"></el-input>
      <div class="flexCE" style="margin-top: 50px;">
        <div class="settingCancel" @click="markCancel">取消</div>
        <div class="settingConfirm" @click="markSubmit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { billQuery, billClose, billOrderNumber, indentMark } from '@/api/reception'
import Bill from '@/components/recepion/bill'
import FinishOrder from '@/components/recepion/finishOrder'
import BillDetail from '@/components/recepion/billDetail'
import Distribute from '@/components/recepion/distribute'
export default {
  name: 'receptionBill',
  components: {
    Bill, // 开单
    FinishOrder, // 完成订单
    BillDetail, // 订单详情
    Distribute // 分配美容师
  },
  props: ['roleBtn'],
  data () {
    return {
      searchKey: '',
      searchDate: '',
      keyType: '',
      stateType: [{ name: '待分配', value: 2 }, { name: '待核销', value: 1 }],
      pageType: 1, // [1]开单列表 [2]开单表单 [3]已完成订单 [4]订单详情 [5]分配美容师
      detailType: '',
      page: 1,
      size: 10,
      total: 0,
      orderList: [],
      orderAll: '',
      editId: null, // 订单id,
      userid: null, // 已到店跳转来的userid
      showRek: false,
      indmark: '',
      indentMarkId: ''
    }
  },
  created () {
    this.userid = this.$route.params.userid
    if (this.userid) {
      this.pageType = 2
    } else {
      this.init()
    }
  },
  methods: {
    init () {
      let st = ''
      let et = ''
      if (this.searchDate !== '') {
        st = this.searchDate[0]
        et = this.searchDate[1]
      }
      const params = {
        username: this.searchKey,
        indentstate: this.keyType,
        stime: st,
        etime: et,
        page_index: this.page,
        page_size: this.size
      }
      // 列表请求
      billQuery(params).then(res => {
        if (res.status === 1) {
          this.orderList = res.data.data
          this.total = res.data.recordcount
        }
      })
      // 数量统计
      billOrderNumber().then(res => {
        if (res.status === 1) {
          this.orderAll = res.data
        }
      })
    },
    handleSearch () { this.init() }, // 搜索
    handleReset () { // 重置
      this.searchDate = ''
      this.searchKey = ''
      this.keyType = ''
      this.init()
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 点击开单[展示]
    handBill () {
      this.editId = null
      this.userid = null
      this.pageType = 2
    },
    // 关闭本单[返回]
    closeBill () {
      this.pageType = 1
      this.init()
    },
    // 保存本单[生成订单]
    saveBill () {
      this.pageType = 1
      this.init()
    },
    // [生成订单]结款完成
    paySuccess () {
      this.pageType = 1
      this.init()
    },
    // 点击已完成订单
    handComplete () {
      this.pageType = 3
    },
    // 完成订单[返回]
    finishBack () {
      this.pageType = 1
    },
    // 完成订单-点击[详情]
    finishDetail (res) {
      this.detailType = 2
      this.pageType = 4
      this.editId = res.indentid
    },
    // 点击待核销
    handWrite (row) {
      this.pageType = 2
      this.editId = row.indentid
      this.userid = null
    },
    // 订单详情[查看]-未完成
    handDetail (row) {
      this.detailType = 1
      this.pageType = 4
      this.editId = row.indentid
    },
    // 详情[返回]
    detailBack (res) {
      if (res === 1) { // 从[未完成]订单[进去返回]
        this.pageType = 1
      } else if (res === 2) { // 从[已完成]订单[进去返回]
        this.pageType = 3
      }
    },
    // 分配美容师[展示]
    handDistribute (row) {
      this.pageType = 5
      this.editId = row.indentid
    },
    // 分配美容师[关闭]
    distriBack () {
      this.pageType = 1
    },
    // 分配美容师[提交成功]
    distriSuccess () {
      this.pageType = 1
      this.init()
    },
    // 关闭本单[删除]
    handClose (id) {
      this.$confirm('此操作将永久删除订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        billClose({ indentid: id }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    /* 点击备注 */
    handRemarks (item) {
      this.showRek = true
      this.indentMarkId = item.indentid
    },
    /* 取消备注 */
    markCancel () {
      this.showRek = false
      this.indmark = ''
    },
    /* 备注提交 */
    markSubmit () {
      if (this.indmark !== '' || this.indmark !== null) {
        indentMark({ indentid: this.indentMarkId, indmark: this.indmark }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.markCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.warning('请输入备注内容')
      }
    }
  }
}
</script>

<style scoped>
.billCard{
  width: 100%;
  height: calc(100% - 130px);
}
.ico1{ background: rgba(244, 76, 142, 0.1); }
.ico2{ background: rgba(60, 132, 239, 0.1); }
.ico3{ background: rgba(74, 108, 223, 0.1); }
.ico4{ background: rgba(68, 181, 87, 0.1); }
.tit1 { color: #3C84EF; }
.tit2 { color: #32C9C9; }
.tit3 { color: #F44C8E; }
.tit4 { color: #44B557; }
.billCardInfo span {
  font-size: 14px;
  color: #A5B7CC;
  margin-right: 10px;
}
</style>

<template>
  <div class="box100">
    <div class="flexCB">
      <div class="flexAC">
        <el-input v-model="searchKey"  placeholder="姓名/手机号" class="inputSearch"></el-input>
        <el-date-picker v-model="searchDate" type="daterange" value-format="yyyy-MM-dd" class="searchDate"
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="settingCancel" @click="handBack">返回上一级</div>
    </div>
    <div class="billCard flexF scrollBox" style="margin-top: 16px;">
      <template>
        <el-card class="orderCard" v-for="(item, index) in finishList" :key="index">
          <div class="flexCB cardSr">
            <p>{{ item.creatime }}</p>
            <p>已完成</p>
          </div>
          <div class="billCardInfo">
            <p>{{ item.username }}/{{ item.uphone }}</p>
            <p v-for="(tag, eq) in item.serve_name" :key="eq">{{ tag }}</p>
          </div>
          <div class="flexCB cardSr">
            <p>共{{ item.num }}项</p>
            <p>合计：<span style="color: #333;">{{ item.price }}</span></p>
          </div>
          <div class="flexCE billCardBtn">
            <p v-if="item.indmark === ''" @click="handRe(item)">备注</p>
            <p @click="handDetail(item)">详情</p>
          </div>
        </el-card>
      </template>
      <div class="notList" v-show="!finishList.length" style="margin: 15% auto;">
        <img src="../../assets/img/noData.png"/>
        <p>暂无数据</p>
      </div>
    </div>
    <el-pagination
      @current-change="pageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage" style="margin-top: 0px;">
    </el-pagination>

    <!-- 备注   -->
    <el-dialog title="备注" :visible.sync="showRek" width="660px" center class="gyDialog" :close-on-click-modal="false" :before-close="markCancel">
      <el-input class="formTextarea" v-model="indmark" type="textarea" maxlength="200" placeholder="请输备注内容（最多200字）"></el-input>
      <div class="flexCE" style="margin-top: 50px;">
        <div class="settingCancel" @click="markCancel">取消</div>
        <div class="settingConfirm" @click="markSubmit">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { billQuery, indentMark } from '@/api/reception'
export default {
  name: 'finishOrder',
  data () {
    return {
      searchKey: '',
      searchDate: '',
      page: 1,
      size: 10,
      total: 0,
      finishList: [],
      showRek: false,
      indmark: ''
    }
  },
  created () {
    this.getFinish()
  },
  methods: {
    getFinish () {
      let st = ''
      let et = ''
      if (this.searchDate !== '') {
        st = this.searchDate[0]
        et = this.searchDate[1]
      }
      const params = {
        username: this.searchKey,
        indentstate: 3,
        stime: st,
        etime: et,
        page_index: this.page,
        page_size: this.size
      }
      billQuery(params).then(res => {
        if (res.status === 1) {
          this.finishList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    // 搜索
    handleSearch () { this.getFinish() },
    // 重置
    handleReset () {
      this.searchKey = ''
      this.searchDate = ''
      this.getFinish()
    },
    pageChange (page) {
      this.page = page
      this.getFinish()
    },
    // 返回
    handBack () {
      this.$emit('finishBack')
    },
    // 完成订单 [详情]
    handDetail (item) {
      this.$emit('finishDetail', item)
    },
    handRe (item) {
      this.showRek = true
      this.indentMarkId = item.indentid
    },
    /* 取消备注 */
    markCancel () {
      this.showRek = false
      this.indmark = ''
    },
    markSubmit () {
      if (this.indmark !== '' || this.indmark !== null) {
        indentMark({ indentid: this.indentMarkId, indmark: this.indmark }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.markCancel()
            this.getFinish()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.warning('请输入备注内容')
      }
    }
  }
}
</script>

<style scoped>
.billCard{
  width: 100%;
  height: calc(100% - 80px);
}
</style>

<!-- 开单详情 -->
<template>
  <div class="box100 scrollBox">
    <div class="formClass">
      <div class="formTitle" @click="handBack()">订单详情<p class="detailBack"><i class="el-icon-arrow-left"></i>返回</p></div>
      <div class="flexF">
        <div class="detItem"><p>订单编号<span>{{ detailInfo.indentid }}</span></p></div>
        <div class="detItem"><p>订单状态<span>{{ detailInfo.indentstatevalue }}</span></p></div>
        <div class="detItem"><p>下单时间<span>{{ detailInfo.creatime }}</span></p></div>
        <div class="detItem"><p>核销时间<span>{{ detailInfo.verifitime }}</span></p></div>
        <div class="detItem"><p>销售类型<span>{{ detailInfo.salestype }}</span></p></div>
        <div class="detItem"><p>付款方式<span>{{ detailInfo.sysvalue }}</span></p></div>
        <div class="detItem"><p>订单类型<span>{{ detailInfo.indentype }}</span></p></div>
        <div class="detItem"><p>用户名称<span>{{ detailInfo.username }}</span></p></div>
        <div class="detItem"><p>手机号码<span>{{ detailInfo.uphone }}</span></p></div>
        <div class="detItem"><p>服务门店<span>{{ detailInfo.shopname }}</span></p></div>
        <div class="detItem"><p>订单金额<span>{{ detailInfo.indentmoney }}</span></p></div>
        <div class="detItem"><p>优惠金额<span>{{ detailInfo.prefemoney }}</span></p></div>
        <div class="detItem"><p>实付金额<span>{{ detailInfo.paymoney }}</span></p></div>
        <div class="detItem" v-if="detailInfo.indmark !== ''"><p>备注<span>{{ detailInfo.indmark }}</span></p></div>
      </div>
    </div>
    <div class="formClass" style="border-bottom: none;">
      <p class="formTitle">服务商品</p>
      <el-table :data="detailInfo.serve_goods" show-summary>
        <el-table-column
          label="服务产品"
          prop="name">
        </el-table-column>
        <el-table-column
          label="数量"
          prop="num">
        </el-table-column>
        <el-table-column
          label="价格"
          prop="price">
        </el-table-column>
        <el-table-column
          label="服务美容师"
          prop="">
          <template slot-scope="scope" v-if="scope.row.sbecode !== null">{{ scope.row.sbecode }}</template>
        </el-table-column>
        <el-table-column
          label="手工费"
          prop="manual">
        </el-table-column>
      </el-table>
    </div>
    <div class="formClass" style="border-bottom: none;">
      <p class="formTitle">核销明细</p>
      <el-table :data="detailInfo.afterver" show-summary>
        <el-table-column
          label="核销名称"
          prop="">
          <template slot-scope="scope" v-if="scope.row.mark !== null">{{ scope.row.mark }}</template>
        </el-table-column>
        <el-table-column
          label="价格"
          prop="price">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { billOrderDetail } from '@/api/reception'

export default {
  name: 'billDetail',
  props: ['editId', 'type'],
  data () {
    return {
      detailInfo: ''
    }
  },
  created () {
    if (this.editId !== null) {
      this.getOrderInfo()
    }
  },
  methods: {
    getOrderInfo () {
      billOrderDetail({ indentid: this.editId }).then(res => {
        if (res.status === 1) {
          this.detailInfo = res.data.data[0]
        }
      })
    },
    handBack () {
      this.$emit('detailBack', this.type)
    }
  }
}
</script>

<style scoped>
.detailBack{
  position: absolute;
  right: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.detItem{
  width: 30%;
  font-size: 14px;
  margin-top: 8px;
  color: #A5B7CC;
}
.detItem p span{
  color: #333;
  margin-left: 8px;
}
</style>
